import * as React from "react";

import {
  container,
  wrapper,
  title,
  list,
  listItem,
  titleWrapper,
  icon,
} from "./instagram.module.css";

const RIDGWAY_ORIGINALS_INSTAGRAM = 'https://www.instagram.com/ridgwayoriginals/';

export function Instagram () {
  return (
    <div className={wrapper}>
      <div className={container}>
        <div className={titleWrapper}>
          <h2 className={title}>Instagram</h2>
          <a className={icon} href={RIDGWAY_ORIGINALS_INSTAGRAM} target="_blank" rel="noreferrer">
            <img alt="Ridgway Originals on Instagram" src="/instagram-mono.svg" />
          </a>
        </div>
        <ul className={list}>
          <li className={listItem}>
            {process.env.GATSBY_INSTAGRAM_MEDIA_URL_1 !== '' &&
              <a href={RIDGWAY_ORIGINALS_INSTAGRAM} target="_blank" rel="noreferrer">
                <img src={process.env.GATSBY_INSTAGRAM_MEDIA_URL_1} alt="Ridgway Originals on Instagram" loading="lazy" />
              </a>}
          </li>
          <li className={listItem}>
            {process.env.GATSBY_INSTAGRAM_MEDIA_URL_2 !== '' &&
              <a href={RIDGWAY_ORIGINALS_INSTAGRAM} target="_blank" rel="noreferrer">
                <img src={process.env.GATSBY_INSTAGRAM_MEDIA_URL_2} alt="Ridgway Originals on Instagram" loading="lazy" />
              </a>}
          </li>
          <li className={listItem}>
            {process.env.GATSBY_INSTAGRAM_MEDIA_URL_3 !== '' &&
              <a href={RIDGWAY_ORIGINALS_INSTAGRAM} target="_blank" rel="noreferrer">
                <img src={process.env.GATSBY_INSTAGRAM_MEDIA_URL_3} alt="Ridgway Originals on Instagram" loading="lazy" />
              </a>}
          </li>
          <li className={listItem}>
            {process.env.GATSBY_INSTAGRAM_MEDIA_URL_4 !== '' &&
              <a href={RIDGWAY_ORIGINALS_INSTAGRAM} target="_blank" rel="noreferrer">
                <img src={process.env.GATSBY_INSTAGRAM_MEDIA_URL_4} alt="Ridgway Originals on Instagram" loading="lazy" />
              </a>}
          </li>
        </ul>
      </div>
    </div>
  )
}
