import * as React from "react";

import {
  container,
  wrapper,
  backgroundImage,
  image,
  intro,
  introText,
  firstLetter,
} from "./about.module.css";

export function About (props) {
  return (
    <div className={wrapper}>
      <div className={backgroundImage}></div>
      <div className={container}>
        <div className={intro}>
          <div className={image}><img src={`/greg-ridgway.jpg`} alt="Greg Ridgway" /></div>
          <p className={introText}><span className={firstLetter}>H</span>ere at Ridgway Originals everything is slow crafted. With patience and care I create one of a kind pieces, using a variety of hand tools and 30 years of experience. I see these creations reflecting my love of the earth, using wood to mirror the common patterns that nature shows us in all her forms. Everything I create comes from a commitment to sustainability. The wood I use is always salvaged and the methods used to transform and finish it carry a small footprint.<br /><br />-- Greg Ridgway</p>
        </div>
      </div>
    </div>
  )
}
