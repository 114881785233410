import * as React from "react"
import { graphql } from "gatsby"
import { Layout } from "../components/layout"
import { ProductListing } from "../components/product-listing"
import { Hero } from "../custom/hero"
import { Faq } from "../custom/faq"
import { About } from "../custom/about"
import { Contact } from "../custom/contact"
import { Instagram } from "../custom/instagram"

// import {
//   container,
//   intro,
//   callOut,
//   callToAction,
//   deployButton,
// } from "./index.module.css"

export default function IndexPage({ data: { products } }) {
  return (
    <Layout>
      <Hero />
      <ProductListing products={products?.nodes} />
      <About />
      <Faq />
      <Contact />
      <Instagram />
    </Layout>
  )
}

export const query = graphql`
  {
    products: allShopifyProduct(
      # sort: { fields: publishedAt, order: ASC }
      limit: 100
    ) {
      nodes {
        ...ProductCard
      }
      pageInfo {
        hasNextPage
      }
    }
  }
`
