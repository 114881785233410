import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from 'react-scroll';

import {
  container,
  wrapper,
  intro,
  hero,
  left,
  explore,
  right
} from "./hero.module.css";

export function Hero (props) {
  return (
    <div className={wrapper}>
      <div className={container}>
        <div className={hero}>
          <div className={left}>
            <h2 className={intro}><span>Ridgway Originals</span>Sustainable Hand-Carved Wooden Bowls</h2>
            <Link
              key="ExploreSelection"
              className={explore}
              to="products"
              smooth={true}
              offset={typeof window !== 'undefined' ? window.innerWidth < 640 ? -60 : -80 : 0}
              href="#"
              duration={500}
            >
              Explore Selection
            </Link>
          </div>
          <div className={right}>
            <StaticImage
              src="../../static/hero-bowl.webp"
              alt="A Greg Ridgway Original handcarved wooden bowls."
              placeholder="blurred"
              layout="fullWidth"
            />
          </div>
        </div>
      </div>
    </div>
  )
}
