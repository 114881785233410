import * as React from "react";

import {
  container,
  wrapper,
  title,
  form,
  inputWrapper,
  label,
  textfield,
  required,
  textarea,
  button,
} from "./contact.module.css";

export function Contact (props) {
  return (
    <div className={wrapper} id="contact">
      <div className={container}>
        <h2 className={title} id="contact">Contact</h2>
          <form className={form} method="POST" name="contact" data-netlify="true">
            <input type="hidden" name="form-name" value="contact" />
            <div className={inputWrapper}>
              <label className={label}>
                Your name <span className={required}>*</span>
                <input className={textfield} type="text" name="name" required />
              </label>
            </div>
            <div className={inputWrapper}>
              <label className={label}>
                Your email <span className={required}>*</span>
                <input className={textfield} type="email" name="email" required />
              </label>
            </div>
            <div className={inputWrapper}>
              <label className={label}>
                Message <span className={required}>*</span>
                <textarea className={textarea} name="message" required></textarea>
              </label>
            </div>
            <div>
              <button className={button} type="submit">Send</button>
            </div>
            <input type="hidden" name="subject" value="Contact submission from ridgwayoriginals.ca" />
          </form>
      </div>
    </div>
  )
}
