import * as React from "react";

import {
  container,
  // intro,
  wrapper,
  title,
  list,
  listItem,
  q,
  a,
} from "./faq.module.css";

export function Faq (props) {
  return (
    <div className={wrapper} id="faq">
      <div className={container}>
        <h2 className={title}>Frequently Asked Questions</h2>
        <ul className={list}>
          <li className={listItem}>
            <h3 className={q}>Q. How do I care for my bowl/ spoon/platter?</h3>
            <p className={a}>A. These products will dull over time, and even become “sticky”. Washing in soap and water will clean off surface residue . To get the original shine back, put the bowl in a warm oven for a few minutes, then buff with a soft cloth and some mineral oil or beeswax.</p>
          </li>
          <li className={listItem}>
            <h3 className={q}>Q. Are these products food safe?</h3>
            <p className={a}>A. All of the creations at Ridgway Originals are finished with food safe organic compounds such as tung oil, shellac, beeswax and various food grade oils.</p>
          </li>
        </ul>
      </div>
    </div>
  )
}
